import React from 'react';
import map from 'lodash/map';
import replace from 'lodash/replace';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Link from './Link';

const BlogRollTemplateList = ({ posts }) => {
  const { t } = useTranslation();

  return (
    <div className="blog-roll-template-list is-flex is-flex-wrap-wrap">
      {map(posts, ({ node: post }) => (
        <div
          className="blog-roll-template is-flex is-flex-direction-column m-4"
          key={post.id}
        >
          {!!post.frontmatter.featuredimage && (
            <Link to={post.fields.slug}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: post.frontmatter.featuredimage,
                  alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                  width:
                    post.frontmatter.featuredimage.childImageSharp
                      .gatsbyImageData.width,
                  height:
                    post.frontmatter.featuredimage.childImageSharp
                      .gatsbyImageData.height,
                }}
              />
            </Link>
          )}
          <Link to={post.fields.slug}>
            <h5 className="post_titile is-size-4 has-text-black is-uppercase mt-4 mb-1">
              {post.frontmatter.title}
            </h5>
          </Link>
          <div className="post-info is-size-7 mb-5 pb-2">
            <span className="is-uppercase">
              {`${t('blog.posted')} ${post.frontmatter.date}`}
            </span>
          </div>
          <p className="mb-5">{replace(post.excerpt, /[a-zA-Z-]+/, '')}</p>
          <div className="buttons mb-5">
            <button className="bttn linked">
              <div className="hover-flip">
                <span className="first">{t('blog.read_more')}</span>
                <span className="second">{t('blog.read_more')}</span>
              </div>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

BlogRollTemplateList.propTypes = {
  posts: PropTypes.array,
};

export default BlogRollTemplateList;
// post.fields.slug
// post.frontmatter.featuredpost  is-featured
